import React, { Fragment } from "react";
// Bootstrap
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Blurb() {
  return (
    <Fragment>
      <Container fluid>
        <Row>
          
          <Col className="text-center">
            <h2 style={{ color: "#42a5f5" }}>Meet The Team</h2>
            <p>We are a group of UT students hungry to make a difference.</p>
          </Col>
          
        </Row>
        <Row>
          <Col className="text-center" style={{marginLeft:"5%", marginRight:"5%"}}>
            <i>
              Through the COVID-19 pandemic, we created LocoEats to support
              locally-owned restaurants in our communities. Our goal is to help
              users easily find the locally-owned restaurants around them. If
              you like what we do and want to support, feel free to donate or
              help us improve data accuracy by flagging any large restaurant
              chains in search results.{" "}
            </i>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
