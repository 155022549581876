import React from "react";
import { Component, Fragment } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-bootstrap";

export default function NavLinks() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [op, setOp] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOp(true);
  };

  return (
    <Fragment>
      <Nav.Link href="/about">About</Nav.Link>
      <Nav.Link target="_blank" href="mailto:leadership@locoeats.org">
        Contact
      </Nav.Link>
      <NavDropdown
        style={{ zIndex: "1000" }}
        title="Share"
        id="basic-nav-dropdown"
        className={"shareDropdown"}
      >
        <NavDropdown.Item
          style={{ color: "black" }}
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.locoeats.org&quote=Locoeats%20connects%20you%20to%20your%20favorite%20local%20restaurants"
        >
          <FontAwesomeIcon
            icon={faFacebook}
            color="#3b5998"
            style={{ marginRight: "5px" }}
          />
          Facebook
        </NavDropdown.Item>
        <NavDropdown.Item
          style={{ color: "black" }}
          target="_blank"
          href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.locoeats.org&original_referer=https%3A%2F%2Ftwitter.com%2Fshare%3Furl%3Dhttps%253A%252F%252Fwww.locoeats.org"
        >
          <FontAwesomeIcon
            icon={faTwitter}
            color="#00acee"
            style={{ marginRight: "5px" }}
          />
          Twitter
        </NavDropdown.Item>
        <NavDropdown.Item
          style={{ color: "black" }}
          target="_blank"
          href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Flocoeats.org"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            color="#0072b1"
            style={{ marginRight: "5px" }}
          />{" "}
          LinkedIn
        </NavDropdown.Item>
      </NavDropdown>
      <NavLink target="_blank" href="https://www.buymeacoffee.com/locoeats">
        Support Us
      </NavLink>
    </Fragment>
  );
}
