import React from "react";
// Bootstrap
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../../images/logo.png";
import Container from "react-bootstrap/Container";
import NavLinks from "./NavLinks";
import IconNavLinks from "./IconNavLinks"


export default function Navigationbar() {
  return (
    <Navbar collapseOnSelect variant="light" className="color-nav">
      <Container style={{ paddingTop: ".5%", paddingBottom: ".5%" }}>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Loco<span className="red">Eats</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav className="word-navs">
            <NavLinks />
          </Nav>
          <Nav className="icon-navs">
            <IconNavLinks />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
