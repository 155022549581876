import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

import background from "../../images/aboutbanner.png"

export class Banner extends Component {

  render() {
    return (
      <Jumbotron className="about-banner" style={{ paddingTop: "3%", paddingBottom: "3%" }}>
        <Container>
          <h1 style={{fontSize: "5.5vw", marginTop:"10px"}}>
            Loco<span className="red">Eats</span>
          </h1>
        </Container>
      </Jumbotron>
    );
  }
}

export default Banner;
