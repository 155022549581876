import React, { Fragment } from "react";
import BioCard from "./TeamBioCard.js";
import { CardDeck } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import anna from "../../images/anna.jpg";
import SpecialThanksCard from "./SpecialThanksCard.js";

export default function SpecialThanks() {
    return (
        <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
            <Col
                className="mx-auto "
                style={{ marginTop: "1%", minWidth: "50vw", maxWidth: "350px" }}
            >
                {" "}
                <SpecialThanksCard
                    image={anna}
                    name="Anna Qi"
                    bio="For project inspiration and UI/UX design"
                    linkedin={"https://www.linkedin.com/in/anna-qi/"}
                />
            </Col>
        </Row>
    );
}
