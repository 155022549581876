import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ScrollToTop from "./ScrollToTop";

// Pages
import home from "./pages/home";
import search from "./pages/search";
import about from "./pages/about";
// axios.defaults.baseURL = "http://127.0.0.1:5000";

axios.defaults.baseURL = "https://locoeatsdev-3cednkrooa-uc.a.run.app";

// axios.defaults.baseURL = "https://locoeatsQA-3cednkrooa-uc.a.run.app";

// axios.defaults.baseURL = "https://locoeatsprod-3cednkrooa-uc.a.run.app";


function App() {
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <div className="App">
        <Switch>
          <Route exact path="/" component={home} />
          <Route exact path={"/search"} component={search} />
          <Route exact path={"/about"} component={about} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
