import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BioCard = (props) => {
  return (
    <Card style={{ borderRadius: "40px" }} className="shadow p-3 mb-4 ">
      <Card.Body style={{ paddingBottom: "40px" }} className="text-center">
        <Image
          src={props.image}
          roundedCircle
          className="profile-pic shadow-lg"
        />
        <h2 style={{ marginTop: "5%", color: "#42a5f5" }}>{props.name}</h2>
        <hr
          style={{
            width: "40%",
            border: "1.5px solid black",
            borderRadius: "2px",
            marginTop: "10%",
          }}
        ></hr>
        <p style={{ paddingBottom: "5%" }}>{props.bio}</p>
        <a href={props.linkedin} target="__blank">
            <div style={{ position: "absolute", bottom: "3.5%",width: "100%",left: "0px" }}>
          <FontAwesomeIcon
            icon={faLinkedin}
            size={"2x"}
            color="#0072b1"

          />{" "}
            </div>
        </a>
      </Card.Body>
    </Card>
  );
};

export default BioCard;
