import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

class MapContainer extends Component {
  render() {
    const { restaurantData, center, zoom } = this.props;
    let markerArr = [];
    let renderMarkers = (map, maps) => {
      let bounds = new maps.LatLngBounds();
      // Set Restaurant Markers, labels, and infowindows
      for (let i = 0; i < restaurantData.length; i++) {
        let restaurantLocation = {
          lat: restaurantData[i].coordinates.latitude,
          lng: restaurantData[i].coordinates.longitude,
        };
        let restaurantTitle = restaurantData[i].name;
        let marker = new maps.Marker({
          position: restaurantLocation,
          map: map,
          title: restaurantData[i].name,
          label: "" + (i + 1),
        });
        let infowindow = new maps.InfoWindow({
          content: restaurantTitle,
        });
        //Show label when hover
        marker.addListener("mouseover", function () {
          infowindow.open(map, this);
        });
        //Don't show label when not hovering
        marker.addListener("mouseout", function () {
          infowindow.close();
        });
        markerArr.push(marker);
        bounds.extend(restaurantLocation);
      }

      if (center.lat != 0.0 && center.lng != 0.0) {
        // Add User's Current Location
        let marker = new maps.Marker({
          position: center,
          map: map,
          title: "Current Location",
          icon: {
            url: "https://maps.google.com/mapfiles/kml/pal3/icon56.png",
          },
        });
        let infowindow = new maps.InfoWindow({
          content: "Current Location",
        });
        marker.addListener("mouseover", function () {
          infowindow.open(map, this);
        });
        marker.addListener("mouseout", function () {
          infowindow.close();
        });
        markerArr.push(marker);
        bounds.extend(center);
      }

      // Adjust bounds and center, return array of markers
      map.fitBounds(bounds);
      return markerArr;
    };

    return (
      // Important! Always set the container height explicitly
      <div className="GoogleMap" style={{}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB-SmU20R_dHq7kNarjr-n2OmPvI9eX5Sc" }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        ></GoogleMapReact>
      </div>
    );
  }
}

export default MapContainer;
