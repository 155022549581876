import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
const CuisineCard = (props) => {
  return (
    <Card className="darkenCard" style={{ backgroundColor: "#F2EAD0", borderRadius: "40px" }}>
      <Card.Img onClick={props.search} className="card-img-top" src={props.image} />

      <Card.Body className="text-center buttonImageCard">
        <button
          className="cuisineButton btn btn-block"
          onClick={props.search}
        >
            <bold style={{fontSize: "1.2em"}}>{props.title}</bold>
        </button>
      </Card.Body>
    </Card>
  );
};

export default CuisineCard;
