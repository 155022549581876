import React, { Fragment } from "react";

// Components
import Navigationbar from "../components/NavAndFoot/Navigationbar";
import Banner from "../components/About/Banner";
import TeamBlurb from "../components/About/TeamBlurb";
import TeamBios from "../components/About/TeamBios";
import SpecialThanks from "../components/About/SpecialThanks";
import MinimalFooter from "../components/NavAndFoot/MinimalFooter";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function about() {
  return (
    <Fragment>
      <Navigationbar />
      <Banner />
      <TeamBlurb />
      <TeamBios />
      <Row style={{marginRight: "0px", marginLeft: "0px"}}>
        <Col className="text-center">
          <h2 style={{ color: "#42a5f5" }}>Special Thanks To</h2>
        </Col>
      </Row>
      <SpecialThanks />
      <MinimalFooter />
    </Fragment>
  );
}
