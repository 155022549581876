import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";

export class Banner extends Component {
  state = {
    term: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    if (typeof this.props.search === "function") {
      this.props.search(this.state.term);
    }
    event.preventDefault();
  };

  render() {
    let today = new Date();
    let time = today.getHours();
    console.log(time);
    let placeholderText = "";
    if (time >= 5 && time <= 11) placeholderText = "What's For Breakfast?";
    else if (time > 11 && time <= 16) placeholderText = "What's For Lunch?";
    else if (time > 16 && time <= 22) placeholderText = "What's For Dinner?";
    else placeholderText = "Late Night Snack?";
    return (
      <Jumbotron style={{ paddingTop: "10%", paddingBottom: "10%" }}>
        <Container>
          <h1>
            Going <span className="red">Loco</span> for Locally Owned
          </h1>
          <h4
              className="siteDescription"
          style={{fontFamily: "Maven Pro, sans-serif", textAlign: 'center', fontWeight: "100"}}
          >Find and support locally owned restaurants in your area</h4>
          <Form onSubmit={this.handleSubmit}>
            <InputGroup className="mb-3" size="lg">
              <Form.Control
                placeholder={placeholderText}
                name="term"
                aria-label="User Search"
                aria-describedby="basic-addon2"
                onChange={this.handleChange}
                style={{ borderRadius: "40px 0px 0px 40px" }}
              />
              <InputGroup.Append>
                <Button type="submit" className="search-button">
                  Find Food
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </Container>
      </Jumbotron>
    );
  }
}

export default Banner;
