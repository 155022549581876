import React, { Component, Fragment } from "react";
import RestaurantCard from "./RestaurantCard";
import LazyLoad from "react-lazyload";

export class RestaurantDisplay extends Component {
  render() {
    const { restaurantData, ip } = this.props;
    console.log(restaurantData);
    return restaurantData.map((restaurant, index) => (
      <LazyLoad>
        <RestaurantCard
          key={index}
          index={index + 1}
          name={restaurant.name}
          website={restaurant.url}
          ratingStars={restaurant.rating}
          ratingCount={restaurant.review_count}
          price={restaurant.price}
          categories={restaurant.categories}
          distance={restaurant.distance}
          phone={restaurant.display_phone}
          address={restaurant.location}
          image={restaurant.image_url}
        />
      </LazyLoad>
    ));
  }
}

export default RestaurantDisplay;
