import React, { Fragment } from "react";
import BioCard from "./TeamBioCard.js";
import { CardDeck } from "react-bootstrap";

import kevin from "../../images/kevin.JPG";
import tejas from "../../images/tejas.jpg";
import aadhi from "../../images/aadhi.jpg";
export default function Bios() {
  const kevinBio =
    "Kevin is an avid basketballer pursuing a double major in Computer Science and Business. He was inspiried by his sister, Anna Qi, to create LocoEats after witnessing the struggles of locally-owned restaurants in his community and discovering the inability to reliably find these businesses. Some of his favorite loco eats include Halal Bros, Don, and Kerbey Lane. Kevin is driven by the impact he can have on others, and he aspires to use technology in a socially beneficial light.";
  const tejasBio =
    "Tejas is a car enthusiast, wannabe dancer, inconsistent gym rat, and foodie. He especially enjoys eating at the unique and quirky local restaurants around him in Dallas and Austin. Recognizing their great value in society, Tejas joined forces with Kevin and Aadhithya to create Locoeats. He has worked in the software industry and enjoys the thrill of problem solving, but is especially drawn to the immense impact software can have. Tejas dreams to be a social entrepreneur and use the latest technologies to solve difficult community-based problems.";
  const aadhibio =
    "Aadhithya is a programmer with a keen interest in cybersecurity and quantum computing. He considers himself a foodie even though he has probably learned more about different cuisines helping code this website rather than tasting different foods. Jokes aside, when the covid crisis hit, Aadhithya wanted to do his part. So, when two good friends approached him with this idea, He was onboard without hesitation and hopes it will help in any way it can.";

  return (
    <CardDeck
        class={"card-deck biodeck"}
      style={{
        marginTop: "3vh",
        marginBottom: "3vh",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <BioCard
        image={tejas}
        name="Tejas Mehta"
        bio={tejasBio}
        linkedin={"https://www.linkedin.com/in/tejasmehta14/"}
      />
      <BioCard
        image={kevin}
        name="Kevin Qi"
        bio={kevinBio}
        linkedin={"https://www.linkedin.com/in/kevinqiut/"}
      />
      <BioCard
        image={aadhi}
        name="Aadhithya Kannan"
        bio={aadhibio}
        linkedin={"https://www.linkedin.com/in/aadhithya-kannan/"}
      />
    </CardDeck>
  );
}
