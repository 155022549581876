import React, { Component, Fragment } from "react";
import Cuisines from "../components/Home/Cuisines";
import Banner from "../components/Home/Banner";
import Footer from "../components/NavAndFoot/Footer";
import Navigationbar from "../components/NavAndFoot/Navigationbar";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { faCookie } from "@fortawesome/fontawesome-free-solid";
import blmicon from "../images/blmicon.png"
export class home extends Component {
  state = {
    latitude: 0,
    longitude: 0,
    showLocationPopup: false,
    showCookiePopup: false,
  };

  componentDidMount() {
    this.getLocation();
    if (localStorage.getItem("cookie policy") == null) {
      this.setState(
        { showCookiePopup: true },
        localStorage.setItem("cookie policy", "set")
      );
    }
  }

  searchTerm = (term) => {
    const urlEncordedTerm = encodeURI(term);
    this.props.history.push(
      `/search?term=${urlEncordedTerm}&lat=${this.state.latitude}&long=${this.state.longitude}`
    );
  };

  searchCategories = (categories) => {
    const urlEncordedTerm = encodeURI(categories);
    this.props.history.push(
      `/search?categories=${urlEncordedTerm}&lat=${this.state.latitude}&long=${this.state.longitude}`
    );
  };

  getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (response) => {
        this.setState({
          latitude: response.coords.latitude,
          longitude: response.coords.longitude,
          showPopup: false,
        });
      },
      // On Error, most likely user declined location permissions -> Show modal
      () => {
        this.setState({ showLocationPopup: true });
        console.log("error");
      }
    );
  };

  handleLocationClose = () => {
    this.setState({ showLocationPopup: false });
  };

  handleCookieClose = () => {
    this.setState({ showCookiePopup: false });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.state.showLocationPopup}
          onHide={this.handleLocationClose}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>
              Allow Location <FontAwesomeIcon icon={faMapMarkerAlt} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            If prompted for location permissions, please click "Allow" to
            display more accurate results{" "}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#42a5f5" }}
              onClick={this.handleLocationClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showCookiePopup}
          onHide={this.handleCookieClose}
          centered
          className="cookie-modal"
          backdrop="static"
        >
          <Modal.Header >
            <Modal.Title>This Website Uses Cookies 🍪</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            We collect and use cookies to improve user experience. Use of this
            site implies consent. For more information, please read the cookie
            policy located within the privacy policy at the bottom of the page.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#42a5f5" }}
              onClick={this.handleCookieClose}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <Navigationbar />
        <Banner search={this.searchTerm} />
        <Container>
          <Row>
            <h2 className="searchByCuisine">Search By Category</h2>
          </Row>
          <br />
          <Row>
            <Cuisines searchCategory={this.searchCategories} searchTerm={this.searchTerm} />
          </Row>
          <Row style={{marginTop: "5%"}}>
            <Col lg={9}><h2 className="text-center" style={{marginTop:"4.5%"}}>"LocoEats stands with the Black Community in the fight against systemic racism."</h2></Col>
            <Col><Image src={blmicon} className="blm-pic" /></Col>
            
          </Row>
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

export default home;
