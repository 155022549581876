import React, { Fragment, useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoad from "react-lazyload";

// Rating Images
import ZeroStar from "../../images/yelpstars/regular_0.png";
import OneStar from "../../images/yelpstars/regular_1.png";
import OneHalfStar from "../../images/yelpstars/regular_1_half.png";
import TwoStar from "../../images/yelpstars/regular_2.png";
import TwoHalfStar from "../../images/yelpstars/regular_2_half.png";
import ThreeStar from "../../images/yelpstars/regular_3.png";
import ThreeHalfStar from "../../images/yelpstars/regular_3_half.png";
import FourStar from "../../images/yelpstars/regular_4.png";
import FourHalfStar from "../../images/yelpstars/regular_4_half.png";
import FiveStar from "../../images/yelpstars/regular_5.png";

import YelpLogo from "../../images/static_img_yelp.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import HollowFlag from "@fortawesome/fontawesome-free-regular/faFlag";
import PhoneIcon from "@material-ui/icons/Phone";
import SolidFlag from "@fortawesome/fontawesome-free-solid/faFlag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DirectionsIcon from "@material-ui/icons/Directions";

const RestaurantCard = (props) => {
  const [flagged, setFlagged] = useState(false);
  const {
    name,
    website,
    ratingStars,
    ratingCount,
    price,
    categories,
    distance,
    address,
    phone,
    image,
    index,
  } = props;

  // Rating Image Logic
  var ratingImage = "";
  switch (ratingStars) {
    case 0:
      ratingImage = ZeroStar;
      break;
    case 1:
      ratingImage = OneStar;
      break;
    case 1.5:
      ratingImage = OneHalfStar;
      break;
    case 2:
      ratingImage = TwoStar;
      break;
    case 2.5:
      ratingImage = TwoHalfStar;
      break;
    case 3.0:
      ratingImage = ThreeStar;
      break;
    case 3.5:
      ratingImage = ThreeHalfStar;
      break;
    case 4:
      ratingImage = FourStar;
      break;
    case 4.5:
      ratingImage = FourHalfStar;
      break;
    case 5:
      ratingImage = FiveStar;
      break;
    default:
      ratingImage = ZeroStar;
  }

  // Category Logic
  var categoryString = "";
  for (let C in categories) {
    categoryString += categories[C].title + ", ";
  }
  categoryString = categoryString.substr(0, categoryString.length - 2);

  // Distance logic
  let distanceMiles = distance * 0.000621371;
  let roundedDistance = Math.round(distanceMiles * 10) / 10;

  // Location logic
  let location =
    address.display_address[0] +
    ", " +
    address.display_address[address.display_address.length - 1];

  const handleClick = () => {
    setFlagged(!flagged);
    if (!flagged) {
      toast.error("Thanks for reporting! We are working to correct it ✔️", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        bodyClassName: "toast-body",
      });
      let body = { name: name };
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post("/crowdsource", JSON.stringify(body), options)
        .then((res) => console.log(res.data));
    } else {
      let body = { name: name };
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post("/uncrowdsource", JSON.stringify(body), options)
        .then((res) => console.log(res.data));
    }
  };

  return (
    <Fragment>
      <Card
        className="shadow p-3 mb-4 bg-light"
        style={{ borderRadius: "1rem" }}
      >
        <Container>
          <Row>
            <Col className="cardImageCol" xs={4}>
              <LazyLoad>
                <Card.Img
                  className="card-img-left"
                  src={image}
                  alt="No Image Available"
                />
              </LazyLoad>
            </Col>
            <Col className="cardContentCol">
              <Container>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-restaurant">
                      View Restaurant on Yelp
                    </Tooltip>
                  }
                >
                  <a
                    href={website}
                    target="_blank"
                    style={{ position: "absolute", top: "0px", right: "0px" }}
                  >
                    <Image src={YelpLogo} style={{ maxHeight: "40px" }} />
                  </a>
                </OverlayTrigger>
                <Row style={{ width: "80%" }}>
                  <a href={website} className="CardWebsiteTitle"> <h3 style={{ marginTop: "1%" }}>{index + ". " + name}</h3></a>
                </Row>
                <Row>
                  <Image
                    src={ratingImage}
                    alt="rating"
                    style={{
                      marginRight: "2%",
                      marginBottom: "1%",
                      marginTop: "1%",
                    }}
                  />{" "}
                  {ratingCount}
                </Row>
                <Row>
                  <p>
                    <span className={"mobileDistance"}>
                      {roundedDistance} mi.
                    </span>{" "}
                    | {price} | {categoryString}
                  </p>
                </Row>
                <Row style={{ width: "98%" }}>
                  <span className="webLocation">
                    {roundedDistance} mi. |{" "}
                    <a
                      target="_blank"
                      href={"https://google.com/maps/search/" + location}
                    >
                      {location}
                    </a>
                  </span>
                </Row>
                <Row className="phoneNum">
                  <a href={"tel:+1" + { phone }.phone}>
                    <span className="phoneText">{phone}</span>
                    <PhoneIcon className="phoneIcon"></PhoneIcon>
                  </a>
                  <a
                    className="mobileLocation"
                    href={
                      "https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=" +
                      encodeURI(location)
                    }
                  >
                    <DirectionsIcon
                      className={"directionsIcon"}
                    ></DirectionsIcon>
                  </a>
                </Row>
                <div
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  className="flag-icon"
                  onClick={handleClick}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-restaurant">
                        Not Locally Owned?
                      </Tooltip>
                    }
                  >
                    {flagged ? (
                      <FontAwesomeIcon
                        icon={SolidFlag}
                        size={"lg"}
                        style={{ color: "#e60000" }}
                      />
                    ) : (
                      <FontAwesomeIcon icon={HollowFlag} size={"lg"} />
                    )}
                  </OverlayTrigger>
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </Card>
    </Fragment>
  );
};

export default RestaurantCard;
