import React, { Component, Fragment } from "react";
import axios from "axios";

// Bootstrap
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal"
//Images/Icons
import ServerErrorImage from "../images/servererror.svg";
import NoResultsImage from "../images/noresults.svg";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import HollowFlag from "@fortawesome/fontawesome-free-regular/faFlag";

// Components
import RestaurantDisplay from "../components/Search/RestaurantDisplay";
import RestaurantCard from "../components/Search/RestaurantCard";
import MapContainer from "../components/Search/MapContainer";
import MinimalFooter from "../components/NavAndFoot/MinimalFooter";
import Filters from "../components/Search/Filters";
import RestaurantCardSkeleton from "../components/Search/RestaurantCardSkeleton";
import ShareLink from "../components/NavAndFoot/NavLinks";
import IconNavLinks from "../components/NavAndFoot/IconNavLinks";
import NavLinks from "../components/NavAndFoot/NavLinks";
import ReactGA from "react-ga";

export class search extends Component {
  state = {
    lat: 0,
    long: 0,
    loading: true,
    newSearch: false,
    lastSearched: "",
    data: {},
    error: false,
    showBLMmodal: false
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const term = params.get("term");
    const lat = params.get("lat");
    const long = params.get("long");
    const categories = params.get("categories");
    ReactGA.initialize("UA-167955630-1");
    this.setState(
      {
        term: term,
        ...(categories && { categories: categories }),
        lat: lat,
        long: long,
        loading: true,
        error: false,
        ipError: true,
        showBLMmodal: term === "black-owned restaurants"? true: false
      },
      () => {
        const params = {
          ...(term && { term: this.state.term }),
          ...(categories && { categories: categories }),
          latitude: this.state.lat,
          longitude: this.state.long,
          radius: 40000,
        };
        axios
          .get("/server?" + new URLSearchParams(params))
          .then((res) => this.setState({ data: res.data, loading: false }))
          .catch((err) => this.setState({ error: true, loading: false }));
      }
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleBLMClose = () => {
    this.setState({ showBLMmodal: false });
  };

  handleSubmit = (event) => {
    window.scrollTo(0, 0);
    this.setState({ newSearch: true, lastSearched: this.state.term });
    const urlEncordedTerm = encodeURI(this.state.term);
    this.props.history.push(
      `/search?term=${urlEncordedTerm}&lat=${this.state.lat}&long=${this.state.long}`
    );
    const params = {
      term: this.state.term,
      latitude: this.state.lat,
      longitude: this.state.long,
      radius: 40000,
    };
    this.setState({ loading: true, error: false }, () => {
      axios
        .get("/server?" + new URLSearchParams(params))
        .then((res) => this.setState({ data: res.data, loading: false }))
        .catch((err) => this.setState({ error: true, loading: false }));
    });
    event.preventDefault();
  };

  filterSearch = (state) => {
    window.scrollTo(0, 0);
    this.setState({ newSearch: false });
    // Price parameter logic
    let priceParam = "";
    if (state.$pressed) priceParam += "1, ";
    if (state.$$pressed) priceParam += "2, ";
    if (state.$$$pressed) priceParam += "3, ";
    if (state.$$$$pressed) priceParam += "4, ";
    priceParam = priceParam.substr(0, priceParam.length - 2);

    let openNowParam = state.openNowPressed;
    let sortByParam = state.sortBy;
    const params = {
      ...(priceParam && { price: priceParam }),
      ...(openNowParam && { open_now: openNowParam }),
      ...(sortByParam && { sort_by: sortByParam }),
      ...(this.state.term && { term: this.state.term }),
      ...(this.state.categories && { categories: this.state.categories }),
      latitude: this.state.lat,
      longitude: this.state.long,
      radius: 40000,
    };
    this.setState({ loading: true, error: false }, () => {
      axios
        .get("/server?" + new URLSearchParams(params))
        .then((res) => {
          let transactionData = [];
          res.data.forEach((restaurant) =>
            state.deliveryPressed &&
            restaurant.transactions.includes("delivery")
              ? transactionData.push(restaurant)
              : state.takeoutPressed &&
                restaurant.transactions.includes("pickup")
              ? transactionData.push(restaurant)
              : null
          );
          transactionData.length === 0
            ? this.setState({ data: res.data, loading: false })
            : this.setState({ data: transactionData, loading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: true, loading: false });
        });
    });
  };

  render() {
    const noResults = this.state.data.length === 0;
    const lessThan3Results = this.state.data.length < 3;
    return (
      <Fragment>
        <Modal
          show={this.state.showBLMmodal}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>
              New Feature Disclaimer ✊🏿
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            While the majority are, not all of these restaurants may be black-owned as Yelp is still in the progress of tagging restaurants as black-owned. We recommend viewing the restaurant on Yelp directly or any other sites as a form of verification.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#42a5f5" }}
              onClick={this.handleBLMClose}
            >
              I Understand
            </Button>
          </Modal.Footer>
        </Modal>


        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          className="color-nav tallNav"
        >
          <div className="searchBar">
            <Form onSubmit={this.handleSubmit}>
              <InputGroup className="mb-3 actualBar" size="lg">
                <Form.Control
                  value={this.state.term}
                  name="term"
                  aria-label="User Search"
                  aria-describedby="basic-addon2"
                  onChange={this.handleChange}
                  style={{ borderRadius: "40px 0px 0px 40px" }}
                />
                <InputGroup.Append>
                  <Button
                    type="submit"
                    className="search-button"
                    disabled={this.state.loading ? true : false}
                  >
                    Find Food
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
            <br />
          </div>
          <Container style={{ paddingTop: ".5%", paddingBottom: ".5%"}}>
            <Navbar.Brand style={{ zIndex: "100" }} href="/">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              Loco<span className="red">Eats</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <Nav
                className="word-navs"
                style={{ position: "absolute", right: "6%" }}
              >
                <NavLinks />
              </Nav>
              <Nav
                className="icon-navs"
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "6%",
                  display: "flex",
                  flexDirection: "row",
                 zIndex:"101"
                }}
              >
                <IconNavLinks search="true" />
              </Nav>
              <Filters
                search={this.filterSearch}
                newSearch={this.state.newSearch}
              />
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container>
          <Row className="resultsAndMap">
            <Col className="results" sm={8}>
              {this.state.error ? (
                <Image src={ServerErrorImage} className="servererror-img" />
              ) : this.state.loading ? (
                <Fragment>
                  <div
                  style={{paddingTop: "20px"}}>
                  <div
                    className="disclaimer"
                    style={{
                      backgroundColor: "#c3c3c1",
                      // maxWidth: "80%",
                      height: "10px",
                      // marginLeft: "32%",
                      width: "90%",
                      marginLeft: "5%",
                      marginBottom: "20px",
                    }}
                  ></div>
                  </div>
                  {/*<div*/}
                  {/*  className="disclaimer"*/}
                  {/*  style={{*/}
                  {/*    backgroundColor: "#c3c3c1",*/}
                  {/*    maxWidth: "30%",*/}
                  {/*    height: "10px",*/}
                  {/*    marginLeft: "35%",*/}
                  {/*    marginTop: "2%",*/}
                  {/*    marginBottom: "1.5%",*/}
                  {/*  }}*/}
                  {/*></div>*/}
                  <RestaurantCardSkeleton />
                </Fragment>
              ) : noResults ? (
                <Image src={NoResultsImage} className="noresults-img" />
              ) : (

                <Fragment>
                  <div className={"disclaimers"}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disclaimer">
                        <p>
                          We classify a LocoEat as any restaurant with 10 or
                          fewer locations. To ensure that only locally-owned
                          restaurants appear in results, please click the{" "}
                          <FontAwesomeIcon icon={HollowFlag} size={"lg"} /> icon
                          on any large franchises ({">"}10 locations)
                        </p>
                      </Tooltip>
                    }
                    placement="bottom"
                  >
                    <div className="disclaimer leftDisclaimer">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      See any non-locally owned franchises?
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disclaimer">
                        <p>
                          We source our information directly from Yelp. We
                          recommend contacting restaurants directly to verify
                          logistics (hours, pricing, pickup/delivery) prior to
                          ordering.
                        </p>
                      </Tooltip>
                    }
                    placement="bottom"
                  >
                    <div className="disclaimer rightDisclaimer" style={{  }}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      How accurate is the information?
                    </div>
                  </OverlayTrigger>
                </div>
                  <RestaurantDisplay
                    restaurantData={this.state.data}
                    ip={this.state.ip}
                  />
                </Fragment>

              )}
            </Col>
            <Col
              className={
                this.state.error
                  ? "error"
                  : noResults
                  ? "no-results"
                  : "GMapContainer"
              }
            >
              {this.state.error ? (
                <div style={{ marginTop: "10%" }}>
                  <h1>Something Went Wrong </h1>
                  <p style={{ marginTop: "2%" }}>
                    <li>Make sure you are connected to the internet</li>
                    <li>Refresh the page</li>
                    <li>Try again at a later time</li>
                  </p>
                </div>
              ) : this.state.loading ? (
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "500px",
                    height: "80vw",
                  }}
                ></div>
              ) : noResults ? (
                <div style={{ marginTop: "10%" }}>
                  <h1>No Results </h1>
                  <p style={{ marginTop: "2%" }}>
                    <li>Check the spelling or try alternate spellings</li>
                    <li>
                      Try a more general search. e.g. "pasta" instead of
                      "spaghetti"
                    </li>
                    <li>Reduce the number of filters</li>
                  </p>
                </div>
              ) : (
                <MapContainer
                  restaurantData={this.state.data}
                  center={{
                    lat: parseFloat(this.state.lat),
                    lng: parseFloat(this.state.long),
                  }}
                  zoom={12}
                />
              )}
            </Col>
          </Row>
        </Container>
        <MinimalFooter
          noResults={noResults}
          lessThan3Results={lessThan3Results}
          serverError={this.state.error}
        />
      </Fragment>
    );
  }
}
export default search;
