import React, { Component, Fragment } from "react";
// Bootstrap
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import logo from "../../images/whiteLogo.png";
import PrivacyPolicy from "../../images/privacyPolicy.pdf";
import Row from "react-bootstrap/Row";

export class MinimalFooter extends Component {
  render() {
    return (
      <Fragment>
        <Navbar
          collapseOnSelect
          variant="dark"
          className="color-foot"
          fixed={
            this.props.noResults || this.props.lessThan3Results || this.props.serverError? "bottom" : ""
          }
        >
          <Container>
            <Navbar.Brand href="/">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              Loco<span className="black">Eats</span>
              <span> © 2020</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link href={PrivacyPolicy}>Privacy Policy</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Fragment>
    );
  }
}

export default MinimalFooter;
