import React, { Fragment } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

// Rating Images
import ZeroStar from "../../images/yelpstars/regular_0.png";
import OneStar from "../../images/yelpstars/regular_1.png";
import OneHalfStar from "../../images/yelpstars/regular_1_half.png";
import TwoStar from "../../images/yelpstars/regular_2.png";
import TwoHalfStar from "../../images/yelpstars/regular_2_half.png";
import ThreeStar from "../../images/yelpstars/regular_3.png";
import ThreeHalfStar from "../../images/yelpstars/regular_3_half.png";
import FourStar from "../../images/yelpstars/regular_4.png";
import FourHalfStar from "../../images/yelpstars/regular_4_half.png";
import FiveStar from "../../images/yelpstars/regular_5.png";

import YelpLogo from "../../images/static_img_yelp.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const RestaurantCardSkeleton = (props) => {
  const content = Array.from({ length: 3 }).map((item, index) => (
    <Card
      className="shadow p-3 mb-4 bg-light"
      style={{ borderRadius: "1rem" }}
      key={index}
    >
      <Container>
        <Row>
          <Col xs={4}>
            <div
              className="card-img-left"
              style={{ backgroundColor: "#c3c3c1" }}
            />
          </Col>
          <Col>
            <Container>
              <Image
                src={YelpLogo}
                style={{
                  maxHeight: "40px",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              />

              <Row style={{ width: "80%" }}>
                <div
                  style={{
                    backgroundColor: "grey",
                    width: "100%",
                    height: "35px",
                    marginTop: "10px",
                  }}
                ></div>
              </Row>
              <Row>
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "33%",
                    height: "20px",
                    marginTop: "10px",
                  }}
                ></div>
              </Row>
              <Row>
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "20px",
                    height: "15px",
                    marginTop: "8px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                </div>{" "}
                |{"  "}
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "20%",
                    height: "15px",
                    marginTop: "8px",
                    marginLeft: "5px",
                  }}
                ></div>
              </Row>
              <br />
              <Row>
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "50px",
                    height: "15px",
                    marginTop: "7px",
                    marginRight: "5px",
                  }}
                ></div>{" "}
                |{" "}
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "50%",
                    height: "15px",
                    marginTop: "7px",
                    marginLeft: "5px",
                  }}
                ></div>
              </Row>
              <Row>
                <div
                  style={{
                    backgroundColor: "#c3c3c1",
                    width: "27%",
                    height: "15px",
                    marginTop: "7px",
                  }}
                ></div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Card>
  ));
  return <Fragment>{content}</Fragment>;
};

export default RestaurantCardSkeleton;
