import React, { Component, Fragment } from "react";

// Bootstrap
import CardDeck from "react-bootstrap/CardDeck";
import CuisineCard from "./CuisineCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";
import CarouselItem from "react-bootstrap/CarouselItem";
import Card from "react-bootstrap/Card";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareRight,
  faCaretSquareLeft,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

// Images
import American from "../../images/cuisines/american.jpg";
import Chinese from "../../images/cuisines/chinese.jpg";
import Italian from "../../images/cuisines/italian.jpg";
import Mexican from "../../images/cuisines/mexican.jpg";
import Indian from "../../images/cuisines/indian.jpg";
import Mediterranean from "../../images/cuisines/mediterranean.jpg";
import Thai from "../../images/cuisines/thai.jpg";
import Japanese from "../../images/cuisines/japanese.jpg";
import Viet from "../../images/cuisines/viet.jpg";
import Desserts from "../../images/cuisines/dessert.jpg";
import BLM from "../../images/cuisines/blm.png";
import Vegan from "../../images/cuisines/vegan.jpg";
import African from "../../images/cuisines/african.jpg";
import Breakfast from "../../images/cuisines/breakfast.jpg";
import Coffee from "../../images/cuisines/coffee.jpg";
import FoodTruck from "../../images/cuisines/foodtruck.jpg";

export class Cuisines extends Component {

  //Search Category
  search = (category) => {
    this.props.searchCategory(category);
  };

  render() {
    const nextIcon = (
      <FontAwesomeIcon
        icon={faArrowCircleRight}
        size="4x"
        color="#42a5f5"
        style={{ marginLeft: "100px" }}
      />
    );

    const prevIcon = (
      <FontAwesomeIcon
        icon={faArrowCircleLeft}
        size="4x"
        color="#42a5f5"
        style={{ marginRight: "100px" }}
      />
    );

    return (
      <Fragment>
        <Carousel
          indicators={true}
          nextIcon={nextIcon}
          prevIcon={prevIcon}
          // wrap={false}
          fade={false}
          touch={true}
          // interval={null}
        >
          <Carousel.Item>
            <CardDeck>
              <CuisineCard
                title="Black-Owned"
                image={BLM}
                search={() => this.props.searchTerm("black-owned restaurants")}
              />
              <CuisineCard
                  title="Italian"
                  image={Italian}
                  search={() => this.search("italian")}
              />
              <CuisineCard
                  title="Mexican"
                  image={Mexican}
                  search={() => this.search("mexican")}
              />
              <CuisineCard
                  title="Vegan"
                  image={Vegan}
                  search={() => this.search("vegan")}
              />
            </CardDeck>
          </Carousel.Item>
          <Carousel.Item>
            <CardDeck>
              <CuisineCard
                  title="Indian"
                  image={Indian}
                  search={() => this.search("indpak")}
              />
              <CuisineCard
                  title="Mediterranean"
                  image={Mediterranean}
                  search={() => this.search("mediterranean")}
              />
              <CuisineCard
                  title="Chinese"
                  image={Chinese}
                  search={() => this.search("chinese")}
              />
              <CuisineCard
                  title="Thai"
                  image={Thai}
                  search={() => this.search("thai")}
              />
            </CardDeck>
          </Carousel.Item>
          <Carousel.Item>
            <CardDeck>
              <CuisineCard
                title="American"
                image={American}
                search={() => this.search("tradamerican, newamerican")}
              />

              <CuisineCard
                  title="Vietnamese"
                  image={Viet}
                  search={() => this.search("vietnamese")}
              />
              <CuisineCard
                  title="Japanese"
                  image={Japanese}
                  search={() => this.search("japanese")}
              />
              <CuisineCard
                  title="African"
                  image={African}
                  search={() => this.search("african")}
              />

            </CardDeck>
          </Carousel.Item>
          <Carousel.Item>
            <CardDeck>

              <CuisineCard
                  title="Desserts"
                  image={Desserts}
                  search={() => this.search("desserts")}
              />
              <CuisineCard
                  title="Breakfast & Brunch"
                  image={Breakfast}
                  search={() => this.search("breakfast_brunch")}
              />

              <CuisineCard
                  title="Coffee & Tea"
                  image={Coffee}
                  search={() => this.search("coffee")}
              />
              <CuisineCard
                  title="Food Trucks"
                  image={FoodTruck}
                  search={() => this.search("foodtrucks")}
              />
            </CardDeck>
          </Carousel.Item>
        </Carousel>
      </Fragment>
    );
  }
}

export default Cuisines;
