import React, { Component, Fragment } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faBoxOpen } from "@fortawesome/fontawesome-free-solid";
import { faTruck } from "@fortawesome/fontawesome-free-solid";
import FormControl from "react-bootstrap/FormControl";
export class Filters extends Component {
  state = {
    $pressed: false,
    $$pressed: false,
    $$$pressed: false,
    $$$$pressed: false,
    takeoutPressed: false,
    deliveryPressed: false,
    openNowPressed: false,
    sortBy: "best_match",
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.newSearch == true && prevProps.newSearch == false) {
      this.setState({
        $pressed: false,
        $$pressed: false,
        $$$pressed: false,
        $$$$pressed: false,
        takeoutPressed: false,
        deliveryPressed: false,
        openNowPressed: false,
        sortBy: "best_match",
      });
    }
  };

  handle$Click = () => {
    this.setState({ $pressed: !this.state.$pressed }, () => {
      this.props.search(this.state);
    });
  };

  handle$$Click = () => {
    this.setState({ $$pressed: !this.state.$$pressed }, () => {
      this.props.search(this.state);
    });
  };

  handle$$$Click = () => {
    this.setState({ $$$pressed: !this.state.$$$pressed }, () => {
      this.props.search(this.state);
    });
  };

  handle$$$$Click = () => {
    this.setState({ $$$$pressed: !this.state.$$$$pressed }, () => {
      this.props.search(this.state);
    });
  };

  handleTakeoutClick = () => {
    this.setState({ takeoutPressed: !this.state.takeoutPressed }, () => {
      this.props.search(this.state);
    });
  };

  handleDeliveryClick = () => {
    this.setState({ deliveryPressed: !this.state.deliveryPressed }, () => {
      this.props.search(this.state);
    });
  };

  handleOpenNowClick = () => {
    this.setState({ openNowPressed: !this.state.openNowPressed }, () => {
      this.props.search(this.state);
    });
  };

  handleSortByChange = (event) => {
    this.setState({ sortBy: event.target.value }, () => {
      this.props.search(this.state);
    });
  };

  render() {
    return (
      <div className="fixedFilters">
        <div className="filters">
          <ButtonGroup aria-label="Basic example" size="sm">
            <Button
              name="$pressed"
              className={
                this.state.$pressed
                  ? "pressed-button shadow-none filterButton"
                  : "unpressed-button shadow-none filterButton"
              }
              style={{
                borderRadius: "10px 0px 0px 10px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              onClick={this.handle$Click}
            >
              $
            </Button>
            <Button
              className={
                this.state.$$pressed
                  ? "pressed-button shadow-none filterButton"
                  : "unpressed-button shadow-none filterButton"
              }
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              onClick={this.handle$$Click}
            >
              $$
            </Button>
            <Button
              className={
                this.state.$$$pressed
                  ? "pressed-button shadow-none filterButton"
                  : "unpressed-button shadow-none filterButton"
              }
              style={{ paddingLeft: "11px", paddingRight: "11px" }}
              onClick={this.handle$$$Click}
            >
              $$$
            </Button>
            <Button
              className={
                this.state.$$$$pressed
                  ? "pressed-button shadow-none filterButton"
                  : "unpressed-button shadow-none filterButton"
              }
              style={{ borderRadius: "0px 10px 10px 0px" }}
              onClick={this.handle$$$$Click}
            >
              $$$$
            </Button>
          </ButtonGroup>

          <ButtonGroup>
            <Button
              className={
                this.state.takeoutPressed
                  ? "pressed-button shadow-none filterButton"
                  : "unpressed-button shadow-none filterButton"
              }
              style={{
                borderRadius: "30px 0px 0px 30px",
                padding: ".25rem .5rem",
              }}
              onClick={this.handleTakeoutClick}
            >
              <FontAwesomeIcon
                icon={faBoxOpen}
                color="white"
                style={{ marginRight: "5px" }}
              />
              Takeout
            </Button>
            <Button
              className={
                this.state.deliveryPressed
                  ? "pressed-button shadow-none filterButton"
                  : "unpressed-button shadow-none filterButton"
              }
              style={{
                borderRadius: "0px 30px 30px 0px",
                padding: ".25rem .5rem",
              }}
              onClick={this.handleDeliveryClick}
            >
              <FontAwesomeIcon
                icon={faTruck}
                color="white"
                style={{ marginRight: "5px" }}
              />
              Delivery
            </Button>
          </ButtonGroup>

          <Button
            className={
              this.state.openNowPressed
                ? "pressed-button shadow-none filterButton"
                : "unpressed-button shadow-none filterButton"
            }
            style={{
              borderRadius: "30px 30px 30px 30px",
              padding: ".25rem .5rem",
            }}
            onClick={this.handleOpenNowClick}
          >
            <FontAwesomeIcon
              icon={faClock}
              color="white"
              style={{ marginRight: "5px" }}
            />
            Open Now
          </Button>

          <FormControl
            as="select"
            custom
            className="unpressed-button shadow-none filterButton"
            style={{
              width: "140px",
              color: "white",
              borderRadius: "30px 30px 30px 30px",
            }}
            value={this.state.sortBy}
            onChange={this.handleSortByChange}
          >
            <option value="distance">Distance</option>
            <option value="rating">Rating</option>
            <option value="review_count">Review Count</option>
            <option value="best_match">Best Match</option>
          </FormControl>
        </div>
      </div>
    );
  }
}

export default Filters;
