import React, { Fragment } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import YelpLogo from "../../images/static_img_yelp.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import HollowFlag from "@fortawesome/fontawesome-free-regular/faFlag";
import PhoneIcon from "@material-ui/icons/Phone";
import SolidFlag from "@fortawesome/fontawesome-free-solid/faFlag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DirectionsIcon from "@material-ui/icons/Directions";

const SpecialThanksCard = (props) => {
    let { image, name, bio, linkedin } = props;
    return (
        <Fragment>
            <Card
                className="shadow p-3 mb-4 bg-light"
                style={{ borderRadius: "1rem" }}
            >
                <Container>
                    <Row>
                        <Col className="cardImageCol annaImage" xs={4}>
                            <Card.Img
                                className="card-img-left"
                                src={image}
                                alt="No Image Available"
                                style={{ minHeight: "90%", marginTop: "5%" }}
                            />
                        </Col>
                        <Col className="cardContentCol">
                            <Container>
                                <a href={props.linkedin} target="__blank">
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        size={"2x"}
                                        color="#0072b1"
                                        style={{
                                            position: "absolute",
                                            top: "0%",
                                            right: "0%",
                                        }}
                                    />{" "}
                                </a>
                                <Row style={{ width: "80%" }}>
                                    <h3
                                        style={{
                                            marginTop: "1%",
                                            color: "#42a5f5",
                                        }}
                                    >
                                        {name}
                                    </h3>
                                </Row>
                                <Row>
                                    <p>
                                        Anna is a UT graduate with a BBA major
                                        in Marketing and a minor in MIS who is
                                        passionate about building and bringing
                                        tech products to market. Witnessing the
                                        closure of many locally-owned
                                        restaurants during the pandemic and the
                                        disarray of information shared online,
                                        Anna founded the idea of a search engine
                                        to discover and support locally owned
                                        businesses in your community. She has
                                        contributed project inspiration, product
                                        marketing, UI/UX design, and leadership
                                        guidance to LocoEats.
                                    </p>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </Fragment>
    );
};

export default SpecialThanksCard;
